import React, { useEffect, useState } from 'react';
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react';
import axios from 'axios';
import { ethers } from 'ethers'
import { Link } from 'react-router-dom';
import { IoIosCheckmarkCircle } from "react-icons/io";
import back1 from "../src/img/level-1.jpg"
import back2 from "../src/img/level-2.jpg"
import back3 from "../src/img/level-3.jpg"
import back4 from "../src/img/level-top.jpg"
import nft_winner from './img/nft-winner.jpg'
import ABI from './contractABI.json'

const Level_5_finish = () => {
    const { address } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();
    const [minted, setMinted] = useState(false);
    const [claiming, setClaiming] = useState(false);
    const contractAddress = '0xbb9F6309e742Eb3db4c2927C4e26EfF1A7FC1484'
    const requiredChainId = '0x2105'
    const admin = '0x8306D7B380AdA3F71b1d0B53b39F9d8319C08832'
    const contractABI = [
        {
            "inputs": [
                {
                    "components": [
                        { "internalType": "address", "name": "to", "type": "address" },
                        { "internalType": "address", "name": "royaltyRecipient", "type": "address" },
                        { "internalType": "uint256", "name": "royaltyBps", "type": "uint256" },
                        { "internalType": "address", "name": "primarySaleRecipient", "type": "address" },
                        { "internalType": "string", "name": "uri", "type": "string" },
                        { "internalType": "uint256", "name": "pricePerToken", "type": "uint256" },
                        { "internalType": "address", "name": "currency", "type": "address" },
                        { "internalType": "uint128", "name": "validityStartTimestamp", "type": "uint128" },
                        { "internalType": "uint128", "name": "validityEndTimestamp", "type": "uint128" },
                        { "internalType": "bytes32", "name": "uid", "type": "bytes32" }
                    ],
                    "internalType": "struct MintRequest",
                    "name": "_req",
                    "type": "tuple"
                },
                { "internalType": "bytes", "name": "_signature", "type": "bytes" }
            ],
            "name": "mintWithSignature",
            "outputs": [
                { "internalType": "uint256", "name": "tokenIdMinted", "type": "uint256" }
            ],
            "stateMutability": "payable",
            "type": "function"
        }
    ];
    const contractMintTo = [
        "function mintTo(address _to, string _uri) returns (uint256)"
    ];

    const Mint = async () => {
        setClaiming(true)
        const signature = await claimStatus2();
        if (signature) {
            await mintWithSignature(signature);
        }
    }

    const mintWithSignature = async (signature) => {
        const provider = new ethers.BrowserProvider(walletProvider);
        const signer = await provider.getSigner()

        const { chainId } = await provider.getNetwork();
        if (chainId !== parseInt(requiredChainId, 16)) {
            try {
                await walletProvider.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: requiredChainId }],
                });
            } catch (switchError) {
                console.error('Error switching network', switchError);
                return;
            }
        }

        const mintRequest = {
            to: address,
            royaltyRecipient: admin,
            royaltyBps: 0,
            primarySaleRecipient: admin,
            uri: 'ipfs://exampleUri',
            quantity: 1,
            pricePerToken: 0,
            currency: ethers.ZeroAddress,
            validityStartTimestamp: Math.floor(Date.now() / 1000),
            validityEndTimestamp: Math.floor(Date.now() / 1000) + 3600,
            uid: ethers.encodeBytes32String('Congratulation! You are Winner!'),
        };


        try {
            console.log(mintRequest)
            //const contract = new ethers.Contract(contractAddress, contractABI, signer);
            //const tx = await contract.mintWithSignature(mintRequest, signature);
            const contract = new ethers.Contract(contractAddress, contractMintTo, signer);
            const tx = await contract.mintTo(address, 'https://ipfs.io/ipfs/QmbFvXML74QJqJF2W8rSWZnsJCtQ7y2cNQ3ue1uLgoWgZk/');
            await tx.wait();
            console.log('Minted successfully');
            setMinted(true);
        } catch (error) {
            console.error('Minting failed', error);
        } finally {
            setClaiming(false);
        }
    };

    const claimStatus2 = async () => {
        try {
            setClaiming(true);
            const provider = new ethers.BrowserProvider(walletProvider)
            const signer = await provider.getSigner()
            const signature = await signer.signMessage('Congratulation! You are Winner!')
            console.log(signature)
            return signature;
        } catch (e) {
            console.error(e);
            setClaiming(false);
            return null;
        }
    };

    const claimStatus = async () => {
        try {
            setClaiming(true);
            const provider = new ethers.BrowserProvider(walletProvider);
            const signer = await provider.getSigner();

            const mintRequest = {
                to: address,
                royaltyRecipient: ethers.ZeroAddress,
                royaltyBps: 0,
                primarySaleRecipient: address,
                uri: 'ipfs://exampleUri',
                quantity: 1,
                pricePerToken: ethers.parseEther('0'),
                currency: ethers.ZeroAddress,
                validityStartTimestamp: Math.floor(Date.now() / 1000),
                validityEndTimestamp: Math.floor(Date.now() / 1000) + 3600,
                uid: ethers.encodeBytes32String('Congratulation! You are Winner!'),
            };

            const encodedRequest = ethers.AbiCoder.defaultAbiCoder.encode(
                ["address", "address", "uint256", "address", "string", "uint256", "uint256", "address", "uint128", "uint128", "bytes32"],
                [
                    mintRequest.to,
                    mintRequest.royaltyRecipient,
                    mintRequest.royaltyBps,
                    mintRequest.primarySaleRecipient,
                    mintRequest.uri,
                    mintRequest.quantity,
                    mintRequest.pricePerToken,
                    mintRequest.currency,
                    mintRequest.validityStartTimestamp,
                    mintRequest.validityEndTimestamp,
                    mintRequest.uid
                ]
            );

            const signature = await signer.signMessage(ethers.getBytes(encodedRequest));
            return signature;
        } catch (e) {
            console.error(e);
            setClaiming(false);
            return null;
        }
    };




    return (
        <>

            <div className='fixed top-0 left-0 -z-10'>
                <img src={back4} className='w-full h-auto opacity-50' />
                <img src={back3} className='w-full h-auto opacity-50' />
                <img src={back2} className='w-full h-auto opacity-50' />
                <img src={back1} className='w-full h-auto opacity-50' />
                <div className='w-full h-full fixed top-0 left-0 min-h-full bg-gradient-to-b from-black/50'></div>
            </div>


            <div className='w-2/3 p-4 fixed items-center justify-center max-w-xl top-[50%] left-[50%] flex-row shadow-md rounded-xl bg-white/90 -translate-x-[50%] -translate-y-[50%]'>
                <div className='flex text-4xl justify-center mt-4'>Congratulation 🎉🎉🎉</div>
                <div className='flex text-4xl justify-center mt-2 mb-4'>You're a great onchain Ninja!</div>
                <div className='flex w-full justify-center items-center my-6'><img src={nft_winner} className='max-w-[30%] h-auto flex rounded-xl justify-center' /></div>
                <button
                    onClick={Mint}
                    disabled={claiming || minted}
                    className={`flex bg-black ${claiming || minted ? 'bg-black/10 text-black/40' : 'hover:bg-[#c993ff] ease-in-out duration-300 text-white hover:text-black'
                        } py-2 w-full rounded-xl justify-center items-center`}
                >
                    {claiming ? 'Minting' : minted ? (
                        <>
                            <IoIosCheckmarkCircle size="20" className="me-2 text-emerald-600/80" />
                             Minted
                        </>
                    ) : 'Mint'}
                </button>
                <div className='flex justify-center w-full text-xs'>Mint your proof of winning</div>
            </div>

        </>
    )
}

export default Level_5_finish