import React, { useState, useEffect } from 'react'
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react';
import axios from 'axios';
import { ethers } from 'ethers';
import { Link } from 'react-router-dom';
import { IoIosCheckmarkCircleOutline, IoIosCheckmarkCircle } from "react-icons/io";
import { MdArrowOutward } from "react-icons/md";
import back from "../src/img/level-3.jpg"
import monster from "../src/img/monster-level-3-green.png"
import monster2 from "../src/img/monster-level-3-cocoon.png"
import ninja from "../src/img/hero-ninja.png"
import done from "../src/img/done.png"
import done2 from "../src/img/done-small.png"

const Level_3 = ({ isLevelGo, setIsLevelGo }) => {
  const [isMonster, setIsMonster] = useState(false);
  const { address } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [loading, setLoading] = useState(false);
  const [claiming, setClaiming] = useState(false);
  const [nft1, setNft1] = useState(null);
  const [nft2, setNft2] = useState(null);
  const [nft3, setNft3] = useState(null);
  const [balanceBUILD, setBalanceBuild] = useState(false);
  const [balanceDEGEN, setBalanceDegen] = useState(false);
  const [balanceOnchainSummer, setBalanceOnchainSummer] = useState(false);

  const ABI = [
    {
      "constant": true,
      "inputs": [
        {
          "name": "owner",
          "type": "address"
        }
      ],
      "name": "balanceOf",
      "outputs": [
        {
          "name": "",
          "type": "uint256"
        }
      ],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
    }
  ];

  const BUILD = '0x3c281a39944a2319aa653d81cfd93ca10983d234'
  const DEGEN = '0x4ed4e862860bed51a9570b96d89af5e1b0efefed'
  const OnchainSummer = '0x768E7151500bB5120983d9619374F31DD71D8357'
  const BaseRpc = 'https://mainnet.base.org'


  const verifyCinematic = async () => {
    setLoading(true);

    const nft1 = '0x87a871998c90a7f7a5058c6e40308d77a98a5232';
    const nft2 = '0xcb4d62a406bc090e11a7f9ba73517a2afb155734';
    const nft3 = '0xbc371f3daa4a76062da6752a0313b86378b0064f';

    try {
      const provider = new ethers.JsonRpcProvider(BaseRpc);

      const nftContract1 = new ethers.Contract(nft1, ABI, provider);
      const nftContract2 = new ethers.Contract(nft2, ABI, provider);
      const nftContract3 = new ethers.Contract(nft3, ABI, provider);

      const [mintedNft1, mintedNft2, mintedNft3] = await Promise.all([
        nftContract1.balanceOf(address),
        nftContract2.balanceOf(address),
        nftContract3.balanceOf(address)
      ]);
      if (mintedNft1 > 0) {
        setNft1(true);
      }
      if (mintedNft2 > 0) {
        setNft2(true);
      }
      if (mintedNft3 > 0) {
        setNft3(true);
      }

      if (mintedNft1 || mintedNft2 || mintedNft3 > 0) {
        setClaiming(true);
        const signature = await claimStatus();
        if (signature) {
          setIsLevelGo(4);
          setClaiming(false);
        } else {
          setLoading(false);
          setClaiming(false);
        }
      }
      else {
        setLoading(false);
        setClaiming(false);
        return
      }
    } catch (e) {
      console.error('Error checking minting status for NFTs:', e);
      setLoading(false);
    }

  };

  const claimStatus = async () => {
    try {
      setClaiming(true);
      const provider = new ethers.BrowserProvider(walletProvider);
      const signer = await provider.getSigner();
      const signature = await signer.signMessage('Congratulation! You have completed Level 3');
      return signature;
    } catch (e) {
      console.error(e);
      return null;
    } finally {
      setClaiming(false);
    }
  };

  const statusBUILD = async () => {
    try {
      const provider = new ethers.JsonRpcProvider(BaseRpc);
      const contract = new ethers.Contract(BUILD, ABI, provider);
      const balance = await contract.balanceOf(address);
      const balanceInBuild = ethers.formatUnits(balance, 18);
      if (balanceInBuild > 0) {
        setBalanceBuild(true);
        return true;
      } else {
        setBalanceBuild(false);
        return false;
      }
    } catch (error) {
      console.error(error);
      setBalanceBuild(false);
      return false;
    }
  };

  const statusDEGEN = async () => {
    try {
      const provider = new ethers.JsonRpcProvider(BaseRpc);
      const contract = new ethers.Contract(DEGEN, ABI, provider);
      const balance = await contract.balanceOf(address);
      const balanceInDegen = ethers.formatUnits(balance, 18);
      if (balanceInDegen > 0) {
        setBalanceDegen(true);
        return true;
      } else {
        setBalanceDegen(false);
        return false;
      }
    } catch (error) {
      console.error(error);
      setBalanceDegen(false);
      return false;
    }
  };

  const statusOnchainSummer = async () => {
    try {
      const provider = new ethers.JsonRpcProvider(BaseRpc);
      const contract = new ethers.Contract(OnchainSummer, ABI, provider);
      const balance = await contract.balanceOf(address);
      const balanceInOnchainSummer = ethers.formatUnits(balance, 18);
      if (balanceInOnchainSummer > 0) {
        setBalanceOnchainSummer(true);
        return true;
      } else {
        setBalanceOnchainSummer(false);
        return false;
      }
    } catch (error) {
      console.error(error);
      setBalanceOnchainSummer(false);
      return false;
    }
  };

  const killMonster = async () => {
    setLoading(true);

    const isOnchainSummer = await statusOnchainSummer();
    const isBUILD = await statusBUILD();
    const isDEGEN = await statusDEGEN();

    if (isOnchainSummer && isBUILD && isDEGEN) {
      await new Promise(resolve => setTimeout(resolve, 2000));
      setIsMonster(true);
    } else {
      return;
    }

    setLoading(false);
  };


  useEffect(() => {
    if (claiming) {
      setClaiming(true);
    }
  }, [claiming]);

  return (
    <div className='flex w-full relative m-0 p-0'>

      {isLevelGo === 1 ? (<>
        <div id="monster" className='absolute block top-0 right-[35%] h-full'>
          <img src={monster} className='h-full w-auto' />
        </div>

        <div id="monster2" className='absolute block top-0 right-[18%] h-full'>
          <img src={monster2} className='h-full w-auto' />
        </div>

        <div className='absolute top-0 left-0 min-w-full min-h-full bg-black/50'></div></>
      ) :
        isLevelGo === 3 ? (!isMonster ? (<>
          <div id="ninja-1" className='absolute block top-0 left-[17%] h-full ease-in-out]'>
            <img src={ninja} className='h-full w-auto' />
          </div>

          <div id="ping" className="animate-ping absolute top-[20%] right-[41%] lg:right-[41%] block xl:h-56 xl:w-56 lg:h-40 lg:w-40 md:h-32 md:w-32 h-24 w-24 rounded-full bg-[#ffdc5d] opacity-75"></div>

          <div id="monster" className='absolute block top-0 right-[35%] h-full'>
            <img src={monster} className='h-full w-auto' />
          </div>

          <div id="monster2" className='absolute block top-0 right-[18%] h-full'>
            <img src={monster2} className='h-full w-auto' />
          </div>

          <div id="info1" className='absolute block lg:top-[10%] top-0 xl:top-[20%] left-[30%] lg:left-[35%] xl:left-[37%] bg-white/90 drop-shadow-md cursor-default rounded-2xl opacity-0 hover:opacity-100 active:opacity-100 focus:opacity-100 ease-in-out duration-300 
      md:w-72 lg:w-80 h-fit md:p-4 lg:p-6 p-2'>
            <div className='flex w-full text-xl mb-4 justify-center'>
              Massive Attack
            </div>
            <div className='flex w-full text-sm mb-2'>
              To win this battle you should have:
            </div>
            <ul className='flex-row w-full text-sm'>
              <li className='flex ms-4 items-center mt-1 link-container'>
                {balanceOnchainSummer ? (
                  <IoIosCheckmarkCircle size="14" className='me-1 text-emerald-500' />
                ) : (
                  <IoIosCheckmarkCircleOutline size="14" className='me-1' />
                )}
                <Link to="https://wallet.coinbase.com/nft/mint/ocsII" target="_blank" className='hover:bg-[#d5abff]'>onchain summer is back NFT</Link>
                <MdArrowOutward size="20" className="text-[#b061ff] opacity-0 arrow-icon ease-in-out duration-300" />
              </li>
              <li className='flex ms-4 items-center mt-1'>
                {balanceBUILD ? (
                  <IoIosCheckmarkCircle size="14" className='me-1 text-emerald-500' />
                ) : (
                  <IoIosCheckmarkCircleOutline size="14" className='me-1' />
                )}
                <span to="#" className='hover:bg-[#d5abff]'>any BUILD</span>
              </li>
              <li className='flex ms-4 items-center mt-1'>
                {balanceDEGEN ? (
                  <IoIosCheckmarkCircle size="14" className='me-1 text-emerald-500' />
                ) : (
                  <IoIosCheckmarkCircleOutline size="14" className='me-1' />
                )}
                <span to="#" className='hover:bg-[#d5abff]'>any DEGEN</span>
              </li>
            </ul>
            {!loading ? (
              <button onClick={killMonster} className='mt-4 bg-black hover:bg-[#c993ff] ease-in-out duration-300 text-white hover:text-black w-full py-1 rounded-lg justify-center'>Fight</button>
            ) : (
              <button disabled={true} className='mt-4 bg-black/20 ease-in-out duration-300 text-black/40 w-full py-1 rounded-lg justify-center'>Verifying</button>
            )} </div>

        </>)
          : (<>
            <div id="done" className='absolute block top-0 right-[35%] h-full'>
              <img src={done} className='h-full w-auto opacity-50' />
            </div>

            <div id="ninja-1" className='absolute block top-0 left-[37%] h-full ease-in-out]'>
              <img src={ninja} className='h-full w-auto' />
            </div>

            <div id="ping2" className="animate-ping absolute top-[10%] lg:top-[10%] right-[22%] lg:right-[22%] block xl:h-32 xl:w-32 lg:h-28 lg:w-28 md:h-20 md:w-20 h-14 w-14 rounded-full bg-[#ffdc5d] opacity-75"></div>

            <div id="monster2" className='absolute block top-0 right-[18%] h-full'>
              <img src={monster2} className='h-full w-auto' />
            </div>

            <div id="info2" className='absolute block lg:top-[5%] top-0 xl:top-[10%] right-[7%] lg:right-[12%] xl:right-[15%] bg-white/90 drop-shadow-md cursor-default rounded-2xl opacity-0 hover:opacity-100 active:opacity-100 focus:opacity-100 ease-in-out duration-300 
      md:w-72 lg:w-80 h-fit md:p-4 lg:p-6 p-2'>
              <div className='flex w-full text-xl mb-4 justify-center'>
                Hidden Attack
              </div>
              <div className='flex w-full text-sm mb-2'>
                Defeat the alien by minting ANY of these NFTs:
              </div>
              <ul className='flex-row w-full text-sm'>
                <li className='flex ms-4 items-center mt-1 link-container'>
                  {nft1 ? (
                    <IoIosCheckmarkCircle size="14" className='me-1 text-emerald-500' />
                  ) : (
                    <IoIosCheckmarkCircleOutline size="14" className='me-1' />
                  )}
                  <Link to="https://cinematiclabs.xyz/base/0xbc371f3daa4a76062da6752a0313b86378b0064f" target="_blank" className='hover:bg-[#d5abff]'>#42 Flood</Link>
                  <MdArrowOutward size="20" className="text-[#b061ff] opacity-0 arrow-icon ease-in-out duration-300" />
                </li>
                <li className='flex ms-4 items-center mt-1 link-container'>
                  {nft2 ? (
                    <IoIosCheckmarkCircle size="14" className='me-1 text-emerald-500' />
                  ) : (
                    <IoIosCheckmarkCircleOutline size="14" className='me-1' />
                  )}
                  <Link to="https://cinematiclabs.xyz/base/0xcb4d62a406bc090e11a7f9ba73517a2afb155734" target="_blank" className='hover:bg-[#d5abff]'>#20 Together</Link>
                  <MdArrowOutward size="20" className="text-[#b061ff] opacity-0 arrow-icon ease-in-out duration-300" />
                </li>
                <li className='flex ms-4 items-center mt-1 link-container'>
                  {nft3 ? (
                    <IoIosCheckmarkCircle size="14" className='me-1 text-emerald-500' />
                  ) : (
                    <IoIosCheckmarkCircleOutline size="14" className='me-1' />
                  )}
                  <Link to="https://cinematiclabs.xyz/base/0x789c789f93edfc23bf33ead32026ee85deb620c8" target="_blank" className='hover:bg-[#d5abff]'>#170 Listen To The Silence</Link>
                  <MdArrowOutward size="20" className="text-[#b061ff] opacity-0 arrow-icon ease-in-out duration-300" />
                </li>
              </ul>
              {!loading ? (
                <button onClick={verifyCinematic} className='mt-4 bg-black hover:bg-[#c993ff] ease-in-out duration-300 text-white hover:text-black w-full py-1 rounded-lg justify-center'>Fight</button>
              ) : claiming ? (
                <button disabled={true} className='mt-4 bg-black/20 ease-in-out duration-300 text-black/40 w-full py-1 rounded-lg justify-center'>Claiming</button>
              )
                : (
                  <button disabled={true} className='mt-4 bg-black/20 ease-in-out duration-300 text-black/40 w-full py-1 rounded-lg justify-center'>Verifying</button>
                )}</div>
          </>)) :
          isLevelGo === 4 ? (<>
            <div id="done" className='absolute block top-0 right-[35%] h-full'>
              <img src={done} className='h-full w-auto opacity-50' />

            </div><div id="done2" className='absolute block top-0 right-[18%] h-full'>
              <img src={done2} className='h-full w-auto opacity-50' />
            </div>


          </>)
            : (<>
              <div id="monster" className='absolute block top-0 right-[35%] h-full'>
                <img src={monster} className='h-full w-auto' />
              </div>

              <div id="monster2" className='absolute block top-0 right-[18%] h-full'>
                <img src={monster2} className='h-full w-auto' />
              </div>

              <div className='absolute top-0 left-0 min-w-full min-h-full bg-gradient-to-b from-black/50 via-black/50'></div></>
            )}

      <img src={back} className='w-full flex' />
    </div>
  )
}

export default Level_3