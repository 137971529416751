import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { useWeb3Modal, useWeb3ModalProvider, useWeb3ModalAccount, useSwitchNetwork } from '@web3modal/ethers/react'
import axios from 'axios';
import Navigation from './Navigation.jsx';
import Footer from './Footer.jsx';
import Level_1 from './Level_1.jsx';
import Level_2 from './Level_2.jsx';
import Level_3 from './Level_3.jsx';
import Level_4_top from './Level_4_top.jsx';
import LevelStart from './LevelStart.jsx';
import Level_5_finish from './Level_5_finish.jsx';

const App = () => {
  const [isLevelGo, setIsLevelGo] = useState(0);
  const [isLevelOneReady, setIsLevelOneReady] = useState(false);
  const [isLevelTwoReady, setIsLevelTwoReady] = useState(false);
  const [isLevelThreeReady, setIsLevelThreeReady] = useState(false);
  const [isLevelFourReady, setIsLevelFourReady] = useState(false);
  const [userData, setUserData] = useState(null);
  const [fid, setFid] = useState(null);

  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();

  const findFid = async () => {
    if (fid === null) {
      try {
      const options = {
        method: 'GET',
        url: 'https://api.neynar.com/v2/farcaster/user/bulk-by-address',
        params: { addresses: address, viewer_fid: '3' },
        headers: {
          accept: 'application/json',
          api_key: process.env.REACT_APP_NEYNAR_API_KEY
        }
      };
      const response = await axios.request(options);
      const data = response.data;
      setUserData(data);
      // Extracting fid
      const userAddress = Object.keys(data)[0];
      const userInfo = data[userAddress][0];
      const userFid = userInfo.fid;
      setFid(userFid);
    } catch (error) {
      console.error (error) 
      
    }
    }
    console.log(fid)
  }

  useEffect(() => {
    if (isLevelGo === 1) {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: 'smooth'
      });
    }
  }, [isLevelGo]);


  const handleChangeLevel = (level) => {
    setIsLevelGo(level);
  };


  return (
    <>
      <BrowserRouter>
        <Navigation />
        {!address ? (
          <LevelStart isLevelGo={isLevelGo} setIsLevelGo={handleChangeLevel} />
        ) : address && isLevelGo === 0 ? (
          <>
           <LevelStart isLevelGo={isLevelGo} setIsLevelGo={handleChangeLevel} />
          </>) : address && isLevelGo === 5 ? (
          <>
           <Level_5_finish isLevelGo={isLevelGo} setIsLevelGo={setIsLevelGo} />
          </>) : (
            <>
             <Level_4_top isLevelGo={isLevelGo} setIsLevelGo={setIsLevelGo} />
            <Level_3 isLevelGo={isLevelGo} setIsLevelGo={setIsLevelGo} />
            <Level_2 isLevelGo={isLevelGo} setIsLevelGo={setIsLevelGo} />
            <Level_1 isLevelGo={isLevelGo} setIsLevelGo={setIsLevelGo} />
            <Footer />
            </>
          )}

      </BrowserRouter>
    </>
  );
}

export default App;
