import React from 'react'
import { useWeb3Modal, createWeb3Modal, defaultConfig, useWeb3ModalAccount } from '@web3modal/ethers/react'
import logo from "./img/OnchainNinjaLogo.png"

const Navigation = () => {
    const { address, chainId, isConnected } = useWeb3ModalAccount();

    // 1. Get projectId
    const projectId = '878e5b8db4073249ebe964fea70f390e'

    // 2. Set chains
    const mainnet = {
        chainId: 8453,
        name: 'Base',
        currency: 'ETH',
        explorerUrl: 'https://basescan.org',
        rpcUrl: 'https://mainnet.base.org'
    }

    // 3. Create a metadata object
    const metadata = {
        name: 'Cinematic',
        description: 'Cinematic Onchain Summer',
        url: 'https://cinematiclabs.xyz', // origin must match your domain & subdomain
        icons: ['']
    }

    // 4. Create Ethers config
    const ethersConfig = defaultConfig({
        /*Required*/
        metadata,

        /*Optional*/
        enableEIP6963: true, // true by default
        enableInjected: true, // true by default
        enableCoinbase: true, // true by default
        rpcUrl: '...', // used for the Coinbase SDK
        defaultChainId: 1 // used for the Coinbase SDK
    })

    // 5. Create a Web3Modal instance
    createWeb3Modal({
        ethersConfig,
        chains: [mainnet],
        projectId,
        enableAnalytics: true // Optional - defaults to your Cloud configuration
    })

    const { open } = useWeb3Modal()

    return (
        <header className='flex w-full py-6 px-2 m-0 bg-black/30'>
            <div className='flex w-full justify-between items-center px-14'>
                <div className='flex text-xl text-white justify-start'><img src={logo} className='flex h-20 w-auto opacity-75'/></div>
                <div className='flex'>
                    {!address ? (
                        <button onClick={() => open()} className='rounded-xl border-none bg-[#644483] hover:bg-[#986cc4] text-white text-md py-1.5 px-8 ease-in-out duration-300'>
                            Connect Wallet</button>)
                        : (
                            <button onClick={() => open()} className='rounded-xl border border-[#986cc4] text-[#986cc4] text-md py-1.5 px-8'>
                                {address.slice(0, 6) + '...' + address.slice(38, 42)}
                            </button>
                        )
                    }
                </div>
            </div>
        </header>
    )
}

export default Navigation