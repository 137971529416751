import React, { useEffect, useState } from 'react';
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react';
import axios from 'axios';
import { ethers } from 'ethers';
import { Link } from 'react-router-dom';
import { IoIosCheckmarkCircleOutline, IoIosCheckmarkCircle } from "react-icons/io";
import { MdArrowOutward } from "react-icons/md";
import back from "../src/img/level-top.jpg"
import monster from "../src/img/monster-boss.png"
import ninja from "../src/img/hero-ninja.png"

const Level_4_top = ({ isLevelGo, setIsLevelGo }) => {
  const { address } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [claiming, setClaiming] = useState(false);
  const [fid, setFid] = useState(null);
  const [followerCount, setFollowerCount] = useState(null);
  const [isFarcaster, setIsFarcaster] = useState(false);
  const [isTx25, setIsTx25] = useState(false);
  const [isBaseContract, setIsBaseContract] = useState(false);

  const BasescanApi = 'https://api.basescan.org/api';

  const findFid = async () => {
    if (fid === null && address) {
      try {
        const options = {
          method: 'GET',
          url: 'https://api.neynar.com/v2/farcaster/user/bulk-by-address',
          params: { addresses: address, viewer_fid: '3' },
          headers: {
            accept: 'application/json',
            api_key: process.env.REACT_APP_NEYNAR_API_KEY,
          },
        };
        const response = await axios.request(options);
        const data = response.data;
        setUserData(data);

        // Extracting fid
        const userAddress = Object.keys(data)[0];
        const userInfo = data[userAddress][0];
        setFid(userInfo.fid);
      } catch (error) {
        console.error('Error finding fid:', error);
      }
    }
  };

  const claimStatus = async () => {
    try {
      setClaiming(true);
      const provider = new ethers.BrowserProvider(walletProvider);
      const signer = await provider.getSigner();
      const signature = await signer.signMessage('Congratulation! You Win!!!');
      return signature;
    } catch (e) {
      console.error(e);
      return null;
    } finally {
      setClaiming(false);
    }
  };

  const verifyStatus = async () => {
    if (!fid) return;
    try {
      setLoading(true);

      // Fetch follower count
      const userFollowers = await fetchFollowerCount();
      if (userFollowers && userFollowers.users && userFollowers.users[0]) {
        setIsFarcaster(userFollowers.users[0].follower_count > 10);
      } else {
        setIsFarcaster(false);
      }

      // Check transactions and contract creation
      const transactions = await checkAddress();

      const TxCount = transactions.length >= 25;
      const TxDeploy = transactions.some(tx => tx.creates !== null);

      setIsTx25(TxCount);
      setIsBaseContract(TxDeploy);

      if (TxCount && TxDeploy && userFollowers) {
        await new Promise(resolve => setTimeout(resolve, 2000));
        setIsLevelGo(5);
      }
    } catch (error) {
      console.error('Error verifying status:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchFollowerCount = async () => {
    const options = {
      method: 'GET',
      url: 'https://api.neynar.com/v2/farcaster/user/bulk',
      params: { fids: fid, viewer_fid: '3' },
      headers: { accept: 'application/json', api_key: process.env.REACT_APP_NEYNAR_API_KEY }
    };

    try {
      const response = await axios.request(options);
      const user = response.data.users[0];
      setFollowerCount(user.follower_count);
      return response.data;
    } catch (error) {
      console.error('Error fetching follower count', error);
      return null;
    }
  };

  const checkAddress = async () => {
    try {
      const response = await axios.get(BasescanApi, {
        params: {
          module: 'account',
          action: 'txlist',
          address: address,
          startblock: 0,
          endblock: 99999999,
          sort: 'asc',
          apikey: process.env.REACT_APP_BASESCAN_API_KEY
        }
      });

      return response.data.result
    } catch (error) {
      console.error('Error checking address:', error);
      //setIsTx25(false);
      //setIsBaseContract(false);
    }
  };

  const checkTx = async () => {
    const transactions = await checkAddress();

    if (transactions.length >= 25) {
      setIsTx25(true);
      return true
    } else {
      setIsTx25(false);
      return false
    }
  }

  const checkContract = async () => {
    const transactions = await checkAddress();
    try {
      const contractCreationFound = transactions.some(tx => tx.creates !== null);
      setIsBaseContract(contractCreationFound);
      return true
    } catch (error) {
      console.error(error)
    }
  }

  //useEffect

  useEffect(() => {
    if (address && !fid) {
      findFid();
    }
  }, [address, fid]);

  useEffect(() => {
    if (claiming) {
      setClaiming(true);
    }
  }, [claiming]);


  return (
    <div className='flex w-full relative m-0 p-0'>

      {isLevelGo <= 2 ? (<>
        <div id="monster" className='absolute block top-0 left-[27%] h-full'>
          <img src={monster} className='h-full w-auto' />
        </div>

        <div className='absolute top-0 left-0 min-w-full min-h-full bg-black/50'></div></>
      ) :
        isLevelGo === 4 ? (<>
          <div id="ninja-1" className='absolute block top-0 right-[32%] h-full ease-in-out scale-x-[-1]'>
            <img src={ninja} className='h-full w-auto' />
          </div>

          <div id="ping" className="animate-ping absolute top-[20%] left-[34%] block xl:h-56 xl:w-56 lg:h-40 lg:w-40 md:h-32 md:w-32 h-24 w-24 rounded-full bg-[#ffdc5d] opacity-75"></div>

          <div id="monster" className='absolute block top-0 left-[27%] h-full'>
            <img src={monster} className='h-full w-auto' />
          </div>

          <div id="info" className='absolute block lg:top-[10%] top-0 xl:top-[20%] left-[25%] lg:left-[30%] xl:left-[32%] bg-white/90 drop-shadow-md cursor-default rounded-2xl opacity-0 hover:opacity-100 active:opacity-100 focus:opacity-100 ease-in-out duration-300 
      md:w-72 lg:w-80 h-fit md:p-4 lg:p-6 p-2'>
            <div className='flex w-full text-xl mb-4 justify-center'>
              The Great Battle
            </div>
            <div className='flex w-full text-sm mb-2'>
              The requirements to beat the Boss are:
            </div>
            <ul className='flex-row w-full text-sm'>
              <li className='flex ms-4 items-center mt-1'>
                {isFarcaster ? (
                  <IoIosCheckmarkCircle size="14" className='me-1 text-emerald-500' />
                ) : (
                  <IoIosCheckmarkCircleOutline size="14" className='me-1' />
                )}
                <span className='hover:bg-[#d5abff] '>10 followers on Farcaster</span>
              </li>
              <li className='flex ms-4 items-center mt-1 link-container'>
                {isBaseContract ? (
                  <IoIosCheckmarkCircle size="14" className='me-1 text-emerald-500' />
                ) : (
                  <IoIosCheckmarkCircleOutline size="14" className='me-1' />
                )}
                <span to="#" className='hover:bg-[#d5abff]'>deployed 1 smart contract on Base</span>
              </li>
              <li className='flex ms-4 items-center mt-1 link-container'>
                {isTx25 ? (
                  <IoIosCheckmarkCircle size="14" className='me-1 text-emerald-500' />
                ) : (
                  <IoIosCheckmarkCircleOutline size="14" className='me-1' />
                )}
                <span to="#" className='hover:bg-[#d5abff]'>more then 25 transactions</span>
              </li>
            </ul>
            {!loading ? (
              <button onClick={verifyStatus} className='mt-4 bg-black hover:bg-[#c993ff] ease-in-out duration-300 text-white hover:text-black w-full py-1 rounded-lg justify-center'>Fight</button>
            ) : claiming ? (
              <button disabled={true} className='mt-4 bg-black/20 ease-in-out duration-300 text-black/40 w-full py-1 rounded-lg justify-center'>Claiming</button>
            )
              : (
                <button disabled={true} className='mt-4 bg-black/20 ease-in-out duration-300 text-black/40 w-full py-1 rounded-lg justify-center'>Verifying</button>
              )} </div></>) :
          (<>
            <div id="monster" className='absolute block top-0 left-[27%] h-full'>
              <img src={monster} className='h-full w-auto' />
            </div>

            <div className='absolute top-0 left-0 min-w-full min-h-full bg-gradient-to-b from-black/50 via-black/50'></div></>
          )}


      <img src={back} className='w-full flex' />
    </div>
  )
}

export default Level_4_top