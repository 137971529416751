import React, { useEffect, useState } from 'react';
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react';
import axios from 'axios';
import { ethers } from 'ethers'

import { Link } from 'react-router-dom';
import { IoIosCheckmarkCircleOutline, IoIosCheckmarkCircle } from "react-icons/io";
import { MdArrowOutward } from "react-icons/md";
import back from "../src/img/level-1.jpg"
import monster from "../src/img/monster-level-1.png"
import done from "../src/img/done.png"
import ninja from "../src/img/hero-ninja.png"

const Level_1 = ({ isLevelGo, setIsLevelGo }) => {
  const { address } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [claiming, setClaiming] = useState(false);
  const [fid, setFid] = useState(null);
  const [isFollowing1, setIsFollowing1] = useState(null);
  const [isFollowing2, setIsFollowing2] = useState(null);
  const [isFollowing3, setIsFollowing3] = useState(null);
  const [nonFid, setNonFid] = useState(false);

  const fetchUserChannel = async (fid, channelId) => {
    try {
      const response = await axios.get('https://api.warpcast.com/v1/user-channel', {
        params: { fid, channelId }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching user channel information:', error);
      throw error;
    }
  };

  const findFid = async () => {
    if (fid === null) {
      try {
        const options = {
          method: 'GET',
          url: 'https://api.neynar.com/v2/farcaster/user/bulk-by-address',
          params: { addresses: address, viewer_fid: '3' },
          headers: {
            accept: 'application/json',
            api_key: process.env.REACT_APP_NEYNAR_API_KEY
          }
        };
        const response = await axios.request(options);
        const data = response.data;
        setUserData(data);
        // Extracting fid
        const userAddress = Object.keys(data)[0];
        const userInfo = data[userAddress][0];
        const userFid = userInfo.fid;
        setFid(userFid);
      } catch (error) {
        console.error(error)
      }
    }
    console.log(fid)
  }

  const findChannel = async (channelId) => {
   
      try {
        const options = {
          method: 'GET',
          url: 'https://api.neynar.com/v2/farcaster/user/channels',
          params: { fid: fid, limit: '100' },
          headers: {
            accept: 'application/json',
            api_key: process.env.REACT_APP_NEYNAR_API_KEY
          }
        };
        const response = await axios.request(options);
        const data = response.data;
        const isOnchainPresent = data.channels.some(channel => channel.id === channelId);

        //setUserData(data);
        return isOnchainPresent;
      } catch (error) {
        console.error('Error fetching user channel information:', error);
        throw error; 
      }
    
  };

    const claimStatus = async () => {
      try {
        setClaiming(true);
        const provider = new ethers.BrowserProvider(walletProvider)
        const signer = await provider.getSigner()
        const signature = await signer.signMessage('Congratulation! You have completed Level 1')
        //console.log(signature)
        return signature;
      } catch (e) {
        console.error(e);
        return null;
      } finally {
        setClaiming(false);
      }
    };

    const verifyStatus = async () => {
      setLoading(true);
      await new Promise(resolve => setTimeout(resolve, 2000));

      if (!fid) {
        //await findFid();
        setLoading(false)
        return;
      }
      try {
        setLoading(true);

        const userChannel1 = await findChannel('base');
        setIsFollowing1(userChannel1);

        const userChannel2 = await findChannel('cinematic');
        setIsFollowing2(userChannel2);

        const userChannel3 = await findChannel('onchainsummer');
        setIsFollowing3(userChannel3);


        if (userChannel1 && userChannel2 && userChannel3) {
          const signature = await claimStatus();
          if (signature) {
            setIsLevelGo(2);
          }
        }
      } catch (error) {
        console.error('Error verifying status:', error);
      } finally {
        setLoading(false);
      }

    };

    // useEffect

    useEffect(() => {
      if (address && !fid) {
        findFid();
      }
    }, [address, fid]);

    useEffect(() => {
      if (claiming) {
        setClaiming(true);
      }
    }, [claiming]);

    useEffect(() => {
      if (nonFid) {
        setNonFid(true);
      }
    }, [nonFid]);

    return (
      <div className='flex w-full relative m-0 p-0'>
        {isLevelGo === 1 ? (
          <>
            <div id="ninja-1" className='absolute block top-0 left-[20%] h-full ease-in-out'>
              <img src={ninja} className='h-full w-auto' alt="Ninja" />
            </div>
            <div id="ping" className="animate-ping absolute top-[20%] right-[22%] lg:right-[24%] block xl:h-56 xl:w-56 lg:h-40 lg:w-40 md:h-32 md:w-32 h-24 w-24 rounded-full bg-[#ffdc5d] opacity-75"></div>
            <div id="monster" className='absolute block top-0 right-[22%] h-full'>
              <img src={monster} className='h-full w-auto' alt="Monster" />
            </div>
            <div id="info" className='absolute block lg:top-[15%] md:top-0 xl:top-[20%] right-[20%] lg:right-[16%] xl:right-[20%] bg-white/90 drop-shadow-md cursor-default rounded-2xl opacity-0 hover:opacity-100 active:opacity-100 focus:opacity-100 ease-in-out duration-300 md:w-72 lg:w-80 h-fit p-2 md:p-4 lg:p-6'>
              <div className='flex w-full text-xl mb-4 justify-center'>
                So Far So Good
              </div>
              <div className='flex w-full text-sm mb-2'>
                Your first task is to become a follower on Farcaster for:
              </div>
              <ul className='flex-row w-full text-sm'>
                <li className='flex ms-4 items-center mt-1 link-container'>
                  {isFollowing1 ? (
                    <IoIosCheckmarkCircle size="14" className='me-1 text-emerald-500' />
                  ) : (
                    <IoIosCheckmarkCircleOutline size="14" className='me-1' />
                  )}
                  <Link to="https://warpcast.com/~/channel/base" target="_blank" className='hover:bg-[#d5abff]'>base</Link>
                  <MdArrowOutward size="20" className="text-[#b061ff] opacity-0 arrow-icon ease-in-out duration-300" />
                </li>
                <li className='flex ms-4 items-center mt-1 link-container'>
                  {isFollowing2 ? (
                    <IoIosCheckmarkCircle size="14" className='me-1 text-emerald-500' />
                  ) : (
                    <IoIosCheckmarkCircleOutline size="14" className='me-1' />
                  )}
                  <Link to="https://warpcast.com/~/channel/cinematic" target="_blank" className='hover:bg-[#d5abff]'>cinematic</Link>
                  <MdArrowOutward size="20" className="text-[#b061ff] opacity-0 arrow-icon ease-in-out duration-300" />
                </li>
                <li className='flex ms-4 items-center mt-1 link-container'>
                  {isFollowing3 ? (
                    <IoIosCheckmarkCircle size="14" className='me-1 text-emerald-500' />
                  ) : (
                    <IoIosCheckmarkCircleOutline size="14" className='me-1' />
                  )}
                  <Link to="https://warpcast.com/~/channel/onchainsummer" target="_blank" className='hover:bg-[#d5abff]'>onchain summer</Link>
                  <MdArrowOutward size="20" className="text-[#b061ff] opacity-0 arrow-icon ease-in-out duration-300" />
                </li>
              </ul>
              {!loading ? (
                <button onClick={verifyStatus} className='mt-4 bg-black hover:bg-[#c993ff] ease-in-out duration-300 text-white hover:text-black w-full py-1 rounded-lg justify-center'>Fight</button>
              ) : claiming ? (
                <button disabled={true} className='mt-4 bg-black/20 ease-in-out duration-300 text-black/40 w-full py-1 rounded-lg justify-center'>Claiming</button>
              ) : (
                <button disabled={true} className='mt-4 bg-black/20 ease-in-out duration-300 text-black/40 w-full py-1 rounded-lg justify-center'>Verifying</button>
              )}
            </div>
          </>
        ) : (
          <>
            <div id="done" className='absolute block top-0 right-[22%] h-full'>
              <img src={done} className='h-full w-auto opacity-50' alt="Done" />
            </div>
          </>
        )}
        <img src={back} className='w-full flex' alt="Background" />
      </div>
    )
  }

  export default Level_1;