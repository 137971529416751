import React from 'react'

const Footer = () => {
    return (
        <footer className='flex w-full py-2 px-4 justify-center items-center m-0 bg-black'>
            <div className='flex w-full justify-center bg-black text-xs text-white/50'>
            Made for Onchain People by Cinematic Labs
            </div>
        </footer>
    )
}

export default Footer