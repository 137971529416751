import React, { useEffect, useState } from 'react';
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react';
import axios from 'axios';
import { ethers } from 'ethers';
import { Link } from 'react-router-dom';
import { IoIosCheckmarkCircleOutline, IoIosCheckmarkCircle } from "react-icons/io";
import { MdArrowOutward } from "react-icons/md";
import back from "../src/img/level-2.jpg";
import monster from "../src/img/monster-level-2.png";
import ninja from "../src/img/hero-ninja.png";
import done from "../src/img/done.png";

const Level_2 = ({ isLevelGo, setIsLevelGo }) => {
  const { address } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [claiming, setClaiming] = useState(false);
  const [fid, setFid] = useState(null);
  const [isLike, setIsLike] = useState(null);
  const [isRecast, setIsRecast] = useState(null);

  const targetHash = '0x8c293556ee01f828b306a1b32a0e7a64d867e06c';

  const fetchReactions = async () => {
    try {
      const options = {
        method: 'GET',
        url: 'https://api.neynar.com/v2/farcaster/cast',
        params: {
          identifier: targetHash,
          type: 'hash',
          viewer_fid: '3',
        },
        headers: {
          accept: 'application/json',
          api_key: process.env.REACT_APP_NEYNAR_API_KEY,
        },
      };
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      console.error('Error fetching reactions:', error);
      return null;
    }
  };

  const findFid = async () => {
    if (fid === null && address) {
      try {
        const options = {
          method: 'GET',
          url: 'https://api.neynar.com/v2/farcaster/user/bulk-by-address',
          params: { addresses: address, viewer_fid: '3' },
          headers: {
            accept: 'application/json',
            api_key: process.env.REACT_APP_NEYNAR_API_KEY,
          },
        };
        const response = await axios.request(options);
        const data = response.data;
        setUserData(data);

        // Extracting fid
        const userAddress = Object.keys(data)[0];
        const userInfo = data[userAddress][0];
        setFid(userInfo.fid);
      } catch (error) {
        console.error('Error finding fid:', error);
      }
    }
  };

  const claimStatus = async () => {
    try {
      setClaiming(true);
      const provider = new ethers.BrowserProvider(walletProvider);
      const signer = await provider.getSigner();
      const signature = await signer.signMessage('Congratulation! You have completed Level 2');
      return signature;
    } catch (e) {
      console.error(e);
      return null;
    } finally {
      setClaiming(false);
    }
  };

  const verifyStatus = async () => {
    if (!fid) return;
    try {
      setLoading(true);
      const userLike = await fetchReactions();
      if (userLike && userLike.cast && userLike.cast.reactions && userLike.cast.reactions.likes && userLike.cast.reactions.recasts) {
        const isLikedByUser = userLike.cast.reactions.likes.some((like) => like.fid === fid);
        setIsLike(isLikedByUser);

        const isRecastByUser = userLike.cast.reactions.recasts.some((recast) => recast.fid === fid);
        setIsRecast(isRecastByUser);

        if (isLikedByUser && isRecastByUser) {
          const signature = await claimStatus();
          if (signature) {
            setIsLevelGo(3);
          }
        }
      }

    } catch (error) {
      console.error('Error verifying status:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (address && !fid) {
      findFid();
    }
  }, [address, fid]);

  useEffect(() => {
    if (claiming) {
      setClaiming(true);
    }
  }, [claiming]);

  return (
    <div className='flex w-full relative m-0 p-0'>
      {isLevelGo === 2 ? (
        <>
          <div id="ninja-1" className='absolute block top-0 right-[17%] h-full ease-in-out scale-x-[-1]'>
            <img src={ninja} className='h-full w-auto' />
          </div>
          <div id="ping" className="animate-ping absolute top-[20%] left-[31%] block xl:h-56 xl:w-56 lg:h-40 lg:w-40 md:h-32 md:w-32 h-24 w-24 rounded-full bg-[#ffdc5d] opacity-75"></div>
          <div id="monster" className='absolute block top-0 left-[27%] h-full'>
            <img src={monster} className='h-full w-auto' />
          </div>
          <div id="info" className='absolute block lg:top-[10%] top-[5%] xl:top-[20%] left-[20%] lg:left-[25%] xl:left-[27%] bg-white/90 drop-shadow-md cursor-default rounded-2xl opacity-0 hover:opacity-100 active:opacity-100 focus:opacity-100 ease-in-out duration-300 md:w-72 lg:w-80 h-fit md:p-4 lg:p-6 p-2'>
            <div className='flex w-full text-xl mb-4 justify-center'>Troubles are Coming</div>
            <div className='flex w-full text-sm mb-2'>The solution how to beat the enemy is:</div>
            <ul className='flex-row w-full text-sm'>
              <li className='flex ms-4 items-center mt-1 link-container'>
              {isLike ? (
                  <IoIosCheckmarkCircle size="14" className='me-1 text-emerald-500' />
                ) : (
                  <IoIosCheckmarkCircleOutline size="14" className='me-1' />
                )}
                <Link to="https://warpcast.com/hashrey/0x8c293556" target="_blank" className='hover:bg-[#d5abff]'>Like this cast on Farcaster</Link>
                <MdArrowOutward size="20" className="text-[#b061ff] opacity-0 arrow-icon ease-in-out duration-300" />
              </li>
              <li className='flex ms-4 items-center mt-1 link-container'>
              {isRecast ? (
                  <IoIosCheckmarkCircle size="14" className='me-1 text-emerald-500' />
                ) : (
                  <IoIosCheckmarkCircleOutline size="14" className='me-1' />
                )}
                <Link to="https://warpcast.com/hashrey/0x8c293556" target="_blank" className='hover:bg-[#d5abff]'>Recast this cast on Farcaster</Link>
                <MdArrowOutward size="20" className="text-[#b061ff] opacity-0 arrow-icon ease-in-out duration-300" />
              </li>
            </ul>
            {!loading ? (
              <button onClick={verifyStatus} className='mt-4 bg-black hover:bg-[#c993ff] ease-in-out duration-300 text-white hover:text-black w-full py-1 rounded-lg justify-center'>Fight</button>
            ) : claiming ? (
              <button disabled={true} className='mt-4 bg-black/20 ease-in-out duration-300 text-black/40 w-full py-1 rounded-lg justify-center'>Claiming</button>
            )
              : (
                <button disabled={true} className='mt-4 bg-black/20 ease-in-out duration-300 text-black/40 w-full py-1 rounded-lg justify-center'>Verifying</button>
              )}
              </div>
        </>
      ) : isLevelGo >= 3 ? (
        <>
          <div id="done" className='absolute block top-0 left-[27%] h-full'>
            <img src={done} className='h-full w-auto opacity-50' />
          </div>
        </>
      ) : (
        <>
          <div id="monster" className='absolute block top-0 left-[27%] h-full'>
            <img src={monster} className='h-full w-auto' />
          </div>
          <div className='absolute top-0 left-0 min-w-full min-h-full bg-gradient-to-b from-black/50 via-black/50'></div>
        </>
      )}
      <img src={back} className='w-full flex' />
    </div>
  );
}

export default Level_2;
