import React, { useState, useEffect } from 'react'
import { useWeb3Modal, useWeb3ModalProvider, useWeb3ModalAccount, useSwitchNetwork } from '@web3modal/ethers/react'
import { ethers } from 'ethers'

import back1 from "../src/img/level-1.jpg"
import back2 from "../src/img/level-2.jpg"
import back3 from "../src/img/level-3.jpg"
import back4 from "../src/img/level-top.jpg"
import hero from "../src/img/ninja-big.png"


const LevelStart = ({ isLevelGo, setIsLevelGo }) => {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [isLevel, setIsLevel] = useState(null);
  const [isVerify, setIsVerify] = useState(false);

  const contract = ''
  const urlRpc = 'https://mainnet.base.org'
  const NFT = ''
  //const client = new NeynarAPIClient(process.env.NEYNAR_API_KEY);

  const { open } = useWeb3Modal()
  const targetChainId = 8453;
  const { switchNetwork } = useSwitchNetwork()

  const openVerify = async () => {
    await open();
    if (!address) {
      console.error("Connect your wallet");
      return;
    }
  }

  const changeLevel = () => {
    if (isLevel === 4) {
      setIsLevelGo(5)
    } else if (isLevel === 3) {
      setIsLevelGo(4)
    } else if (isLevel === 2) {
      setIsLevelGo(3)
    } else if (isLevel === 1) {
      setIsLevelGo(2)
    } else {
      setIsLevelGo(1)
    }

  }

  const verifyStatus = async (e) => {
    e.preventDefault();
    console.log('verify started')
    setIsVerify(true);

    try {
      if (chainId !== targetChainId) {
        const networkChanged = await switchNetwork(targetChainId);
        if (!networkChanged) {
          console.error("Network change required to continue. Please switch to the correct network.");
          setIsVerify(false);
          return;
        }
      }
    } catch (e) {
      console.error(e);
      setIsVerify(false);
      return
    }

    try {
      const provider = new ethers.JsonRpcProvider(urlRpc);
      const nftContract = new ethers.Contract(contract, NFT, provider);
      const Level = await nftContract.Level(address);
      setIsLevel(Level);
    } catch (e) {
      console.error(e);
    }
    console.log(isLevel)
    changeLevel();
  };

  return (<>
    <div id="start" className='absolute top-[45%] right-[22%]'>
      {!address ? (
        <button onClick={openVerify} className='cursor-pointer flex mt-4 px-24 py-4 bg-[#aa54ff] hover:bg-[#c993ff] ease-in-out duration-300 text-white text-2xl hover:text-[#60358a] w-full rounded-lg justify-center'>
          Start</button>)
        : address && !isVerify ? (
          <button onClick={verifyStatus} className='cursor-pointer flex mt-4 px-24 py-4 bg-[#aa54ff] hover:bg-[#c993ff] ease-in-out duration-300 text-white text-2xl hover:text-[#60358a] w-full rounded-lg justify-center'>
            Start</button>
        )
          : (
            <button disabled="true" className='cursor-pointer flex mt-4 px-24 py-4 bg-black/60 ease-in-out duration-300 text-white/20 text-2xl w-full rounded-lg justify-center'>
              Starting</button>
          )}

      <div className='flex text-white/50 justify-center text-xs mt-2'>Onchain Fighting built on Farcaster & Base</div>
    </div>


    <div className='fixed top-0 left-0 -z-10'>
      <img src={back4} className='w-full h-auto opacity-50' />
      <img src={back3} className='w-full h-auto opacity-50' />
      <img src={back2} className='w-full h-auto opacity-50' />
      <img src={back1} className='w-full h-auto opacity-50' />
      <div className='w-full h-full fixed top-0 left-0 min-h-full bg-gradient-to-b from-black/50'></div>
    </div>
    <img src={hero} className='fixed bottom-0 md:left-[12%] lg:left-[10%] xl:left-[12%] w-auto max-h-full -z-10' />
  </>
  )
}

export default LevelStart